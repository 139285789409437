import React, {useState, useEffect} from 'react';
import PricingData from "../../data/pricing/PricingData.json";
import { FaCheck} from "react-icons/fa";


const getPriceingData = PricingData;

const billed = [
    {
        id: 1,
        label: "Billed Yearly"
    },
    {
        id: 2,
        label: "Billed Monthly"
    }
]

const PricingOne = () => {

    const [, setActive] = useState("");

    useEffect(() => {
        setActive(billed[0].label.toLowerCase());
    }, []);

    return (
        <>
            <div className="row">
                {getPriceingData.map((data, index) => (
                    <div className="col-lg-6" key={index}>
                        <div className="pricing-table pricing-borderd">
                            <div className="pricing-header">
                                <h3 className="title">{data.title}</h3>
                                <span className="subtitle">{data.subtitle}</span>
                                <div className="price-wrap">
                                </div>
                            </div>
                            <div className="pricing-body">
                                <ul className="list-unstyled">
                                    {data.facility.map((data, index) => (
                                        <li key={index}><FaCheck /> {data}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}

export default PricingOne;