import React from "react";
import { Link } from "react-router-dom";

const Nav = () => {
  return (
    <nav className="mainmenu-nav">
      <ul className="mainmenu">
        <li>
          <Link to={process.env.PUBLIC_URL + "/hur-fungerar-det"}>Hur fungerar det?</Link>
        </li>
        <li>
          <Link to={process.env.PUBLIC_URL + "/losningar"}>Lösningar</Link>
        </li>
        <li>
          <Link to={process.env.PUBLIC_URL + "/om-oss"}>Om oss</Link>
        </li>
        <li>
          <Link to={process.env.PUBLIC_URL + "/FAQ"}>FAQ</Link>
        </li>
        <li>
          <Link to={process.env.PUBLIC_URL + "/kontakt"}>Kontakt</Link>
        </li>
      </ul>
    </nav>
  );
};

export default Nav;
