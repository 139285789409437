import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import ProcessOne from "../component/process/ProcessOne";
import AboutOne from "../component/about/AboutOne";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import SEO from "../common/SEO";
import FaqOne from "../component/faq/FaqOne";
import CaseStudyProp from "../component/casestudy/CaseStudyProp";
import Testimonials from "./Testimonials";
import FormOne from "../component/contact/FormOne";

import CookieConsent from "react-cookie-consent";

const Home = () => {
  return (
    <>
      <SEO title="Sälj och bo kvar" />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <AboutOne />
        <Testimonials />
        <div className="pt--50 pt_lg--200 pt_md--100 pt_sm--80 case-study-page-area bg-color-light">
          <div className="container">
            <CaseStudyProp />
          </div>
        </div>

        <FaqOne />
        <ProcessOne />
        <div className="section section-padding">
          <div className="container">
            <div className="row">
              <div className="col-xl-8 container">
                <h2 className="title text-left">Ansök och få ett bud</h2>
                <p className="text-left">
                  Skicka dina uppgifter så återkommer vi med ett ungefärligt bud
                  och hyresnivå. Det är givetvis kostnadsfritt och icke
                  bindande.
                </p>
                <FormOne />
              </div>
            </div>
          </div>
        </div>
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default Home;
