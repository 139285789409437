import React from "react";
import { Link } from "react-router-dom";
import {
  FaFacebookF,
  FaInstagram,
} from "react-icons/fa";
import FormSmall from "../../component/contact/FormSmall";

const FooterOne = ({ parentClass }) => {
  return (
    <footer className={`footer-area ${parentClass}`}>
      <div className="container">
        <div className="footer-top">
          <div className="footer-social-link" style={{ padding: 20 }}>
            <ul className="list-unstyled">
              <li>
                <a href="https://facebook.com/lisahem.se">
                  <FaFacebookF />
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/lisahem_sverige">
                  <FaInstagram />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-main">
          <div className="row">
            <div className="col-xl-6 col-lg-5">
              <div className="footer-widget border-end">
                <div className="footer-newsletter">
                  <h2 className="title">Hör av dig</h2>
                  <p>
                    Om du vill veta mer är det bara att skicka din e-post till
                    oss så återkommer vi snarast.
                  </p>
                  <FormSmall />
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-7">
              <div className="row">
                <div className="col-sm-6">
                  <div className="footer-widget">
                    <h6 className="widget-title">LisaHem</h6>
                    <div className="footer-menu-link">
                      <ul className="list-unstyled">
                        <li>
                          {" "}
                          <Link
                            to={process.env.PUBLIC_URL + "/"}
                          >
                            Hem
                          </Link>
                        </li>
                        <li>
                          <Link to={process.env.PUBLIC_URL + "/hur-fungerar-det"}>
                            Hur fungerar det?
                          </Link>
                        </li>
                        <li>
                          <Link to={process.env.PUBLIC_URL + "/losningar"}>
                            Lösningar
                          </Link>
                        </li>
                        <li>
                          {" "}
                          <Link
                            to={
                              process.env.PUBLIC_URL + "/om-oss"
                            }
                          >
                            Om oss
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="footer-widget">
                    <h6 className="widget-title">Support</h6>
                    <div className="footer-menu-link">
                      <ul className="list-unstyled">
                        <li>
                          <Link to={process.env.PUBLIC_URL + "/kontakt"}>
                            Kontakt
                          </Link>
                        </li>
                        <li>
                          <Link to={process.env.PUBLIC_URL + "/FAQ"}>FAQ</Link>
                        </li>
                        <li>
                          {" "}
                          <Link to={process.env.PUBLIC_URL + "/integritetspolicy"}>
                            Integritetspolicy
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="row">
            <div className="col-md-6">
              <div className="footer-copyright">
                <span className="copyright-text">
                  © {new Date().getFullYear()}. Alla rättigheter förbehållna av
                  LisaHem.
                </span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="footer-bottom-link">
                <ul className="list-unstyled">
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/integritetspolicy"}>
                      Integritetspolicy
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterOne;
