import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import Alert from "react-bootstrap/Alert";
import { FaEnvelopeOpen } from "react-icons/fa";

const Result = () => {
  return (
    <Alert variant="success" className="success-msg">
      Tack för ditt meddelande!
    </Alert>
  );
};

const FormSmall = () => {
  const form = useRef();

  const [result, showresult] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_y89gvce",
        "template_zbuvd5g",
        form.current,
        "3WymuU-oTFd-T8FoL"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    form.current.reset();
    showresult(true);
  };

  setTimeout(() => {
    showresult(false);
  }, 5000);

  return (
    <form ref={form} onSubmit={sendEmail} className="lisahem-contact-form">
      <div className="input-group">
        <span className="mail-icon">
          <FaEnvelopeOpen />{" "}
        </span>
        <input
          type="email"
          className="form-control"
          name="epost"
          placeholder="Epost address"
        />
        <button className="subscribe-btn" type="submit">
          Skicka
        </button>
      </div>
      <div className="form-group">{result ? <Result /> : null}</div>
    </form>
  );
};

export default FormSmall;
