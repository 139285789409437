import React from "react";
import { Link } from "react-router-dom";

const AboutFour = () => {
  return (
    <div className="section section-padding case-study-featured-area">
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-6">
            <div className="case-study-featured-thumb text-start">
              <img
                src={process.env.PUBLIC_URL + "/images/new/flex.svg"}
                alt="travel"
              />
            </div>
          </div>
          <div className="col-xl-6 col-lg-6">
            <div className="case-study-featured">
              <div className="section-heading heading-left">
                <span className="subtitle">Vad gör vi?</span>
                <h2 className="title">Flexibilitet för alla</h2>
                <p>
                  Målet med tjänsten LisaHem är att möjliggöra det för individer att strukturera om i sin ekonomi och hitta en flexibel bostadssituation. Ägarna av tjänsten är långsiktiga fastighetsägare och hyresvärdar som brinner för att hjälpa människor och företag att hitta trygga och trevliga hem.
                </p>
                <Link to="/" className="lisahem-btn btn-fill-primary btn-large">
                  Ansök om bud idag
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutFour;
