import React from "react";
import Accordion from "react-bootstrap/Accordion";
import SectionTitle from "../../elements/section-title/SectionTitle";
import FaqData from "../../data/faq/FaqData.json";

const allData = FaqData;

const FaqOne = () => {
  return (
    <div className="section section-padding-equal bg-color-light faq-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 col-xl-4">
            <SectionTitle
              title="Vad våra kunder har lyckats åstadkomma"
              description="Oavsett om det är att betala av skulder, köpa ett nytt hem eller finansiera ett företag, så ger LisaHem dig möjligheten att frigöra kapital utan strikta långivarekvalifikationer. Med vår lösning säljer du din bostad till oss och stannar kvar som hyresgäst, samtidigt som du får chansen att arbeta mot dina mål utan att behöva låna eller flytta."
              textAlignment="heading-left"
              textColor=""
            />
          </div>
          <div className="col-lg-7 col-xl-8">
            <div className="faq-accordion">
              <Accordion defaultActiveKey="1">
                {allData.map((data) => (
                  <Accordion.Item eventKey={data.id} key={data.id}>
                    <Accordion.Header>{data.title}</Accordion.Header>
                    <Accordion.Body>
                      <p>{data.body}</p>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqOne;
