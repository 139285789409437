import React from "react";
import SEO from "../common/SEO";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import FormTwo from "../component/contact/FormTwo";
import SectionTitle from "../elements/section-title/SectionTitle";
import ContactLocation from "../component/contact/ContactLocation";
import FormOne from "../component/contact/FormOne";

const Contact = () => {
  return (
    <>
      <SEO title="Kontakt" />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />

        <div className="section section-padding">
          <div className="container">
            <div className="row">
              <div className="col-xl-5 col-lg-6">
                <div className="contact-form-box shadow-box mb--30 mt--80">
                  <h3 className="title">Kontakta oss</h3>
                  <FormTwo />
                </div>
              </div>
              <div className="col-xl-5 col-lg-6 offset-xl-1">
                <div className="contact-info mb--100 mb_md--30 mt_md--0 mt--150">
                  <h4 className="title">Telefon</h4>
                  <p>Vi har öppet mån-fre 09-17.</p>
                  <h4 className="phone-number">
                    <a href="tel:+46708635333">+46 70-863 53 33 </a>
                  </h4>
                </div>
                <div className="contact-info mb--30">
                  <h4 className="title">Epost</h4>
                  <p>Vi kontaktar dig alltid inom 24h.</p>
                  <h4 className="phone-number">
                    <a href="mailto:info@lisahem.se">info@lisahem.se</a>
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <ul className="list-unstyled shape-group-12">
            <li className="shape shape-1">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"}
                alt="Bubble"
              />
            </li>
            <li className="shape shape-2">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"}
                alt="Bubble"
              />
            </li>
            <li className="shape shape-3">
              <img
                src={process.env.PUBLIC_URL + "/images/others/circle-3.png"}
                alt="Circle"
              />
            </li>
          </ul>
        </div>

        <div className="section section-padding bg-color-white overflow-hidden">
          <div className="container">
            <SectionTitle
              title="Våra kontor"
              description=""
              textAlignment=""
              textColor="dark"
            />
            <div className="row justify-content-center">
              <ContactLocation />
            </div>
          </div>
          <ul className="shape-group-11 list-unstyled">
            <li className="shape shape-1">
              <img
                src={process.env.PUBLIC_URL + "/images/others/line-6.png"}
                alt="line"
              />
            </li>
          </ul>
        </div>
        <div className="section">
          <div className="container">
            <div className="row">
              <div className="col-xl-8 container">
                <h2 className="title text-center">Ansök och få ett bud</h2>
                <p className="text-center">
                  Skicka dina uppgifter så återkommer vi med ett ungefärligt bud
                  och hyresnivå. Det är givetvis kostnadsfritt och icke
                  bindande.
                </p>
                <FormOne />
              </div>
            </div>
          </div>
        </div>
        <FooterOne parentClass="pt--150 pt_lg--100 pt_md--80 pt_sm--60" />
      </main>
    </>
  );
};

export default Contact;
