import React from "react";
import { Link } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import {
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaPhone,
} from "react-icons/fa";

const OffcanvasMenu = ({ offcanvasShow, offcanvasHide }) => {
  return (
    <Offcanvas
      show={offcanvasShow}
      onHide={offcanvasHide}
      placement="end"
      className="header-offcanvasmenu"
    >
      <Offcanvas.Header closeButton></Offcanvas.Header>
      <Offcanvas.Body>
        <div className="row ">
          <div className="col-lg-5 col-xl-6">
            <ul className="main-navigation list-unstyled">
              <li>
                <Link to={process.env.PUBLIC_URL + "/"}>Hem</Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + "/losningar"}>Lösningar</Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + "/om-oss"}>Om oss</Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + "/kontakt"}>Kontakt</Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-7 col-xl-6">
            <div className="contact-info-wrap">
              <div className="contact-inner">
                <address className="address">
                  <span className="title">Kontaktinformation</span>
                  <p>Nybrogatan 38, 112 28 Stockholm</p>
                </address>
                <address className="address">
                  <span className="title">
                    Vi finns tillgängliga mån-fre 09-17.
                  </span>
                  <a href="tel:8884562790" className="tel">
                    <FaPhone /> 1234567890
                  </a>
                </address>
              </div>
              <div className="contact-inner">
                <h5 className="title">Hitta oss här</h5>
                <div className="contact-social-share">
                  <ul className="social-share list-unstyled">
                    <li>
                      <a href="https://facebook.com/">
                        <FaFacebookF />
                      </a>
                    </li>

                    <li>
                      <a href="https://twitter.com/">
                        <FaTwitter />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/">
                        <FaLinkedinIn />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default OffcanvasMenu;
