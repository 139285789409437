import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import Alert from "react-bootstrap/Alert";

const Result = () => {
  return (
    <Alert variant="success" className="success-msg">
      Tack för ditt meddelande!
    </Alert>
  );
};

const FormTwo = () => {
  const form = useRef();

  const [result, showresult] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_y89gvce",
        "template_zbuvd5g",
        form.current,
        "3WymuU-oTFd-T8FoL"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    form.current.reset();
    showresult(true);
  };

  setTimeout(() => {
    showresult(false);
  }, 5000);

  return (
    <form ref={form} onSubmit={sendEmail} className="lisahem-contact-form">
      <div className="form-group">
        <label>Namn</label>
        <input
          type="text"
          className="form-control"
          name="namn"
          required
        />
      </div>
      <div className="form-group">
        <label>Email</label>
        <input
          type="email"
          className="form-control"
          name="epost"
          required
        />
      </div>
      <div className="form-group">
        <label>Telefon</label>
        <input
          type="tel"
          className="form-control"
          name="telefon"
          required
        />
      </div>
      <div className="form-group mb--40">
        <label>Vad kan vi hjälpa dig med?</label>
        <textarea
          className="form-control"
          name="adress"
          rows="4"
        ></textarea>
      </div>
      <div className="form-group">
        <button
          type="submit"
          className="lisahem-btn btn-fill-primary btn-fluid btn-primary"
          name="submit-btn"
        >
          Skicka
        </button>
      </div>
      <div className="form-group">{result ? <Result /> : null}</div>
    </form>
  );
};

export default FormTwo;
