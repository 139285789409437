import React from "react";
import SectionTitle from "../elements/section-title/SectionTitle";
import TestimonialPropTwo from "../component/testimonial/TestimonialPropTwo";
import TestimonialData from "../data/testimonial/TestimonialData.json";
import { slugify } from "../utils";

const allData = TestimonialData;

const Testimonials = () => {
  const googleData = allData.filter(
    (data) => slugify(data.fromtext) !== "dsadsadas"
  );

  return (
    <section className="section section-padding-2 bg-color-light">
      <SectionTitle
        title="Vad våra kunder säger"
        textAlignment=""
        textColor="mb--50"
      />
      <div className="section customer-review-area">
        <div className="container">
          <div className="row">
            <TestimonialPropTwo
              colSize="col-lg-3"
              itemShow="4"
              testimonialData={googleData}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
