import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "./component/scrollToTop/ScrollToTop";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./pages/Home";
import FAQ from "./pages/FAQ";
import AboutUs from "./pages/AboutUs";
import Contact from "./pages/Contact";
import ErrorPage from "./pages/404";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfUse from "./pages/TermsOfUse";
import PricingTable from "./pages/PricingTable";
import HowItWorks from "./pages/HowItWorks";
import * as Sentry from "@sentry/react";

// Css Import
import "./assets/scss/app.scss";

//GA
import ReactGA from "react-ga";
const TRACKING_ID = "UA-240882388-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

// Sentry
Sentry.init({
  dsn: "https://8c80039c55984d2dbf9da88f84cfce7b@o1296562.ingest.sentry.io/6572861",
});

const App = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <Router>
      <ScrollToTop>
        <Routes>
          <Route path={process.env.PUBLIC_URL + "/"} element={<Home />} />
          <Route path={process.env.PUBLIC_URL + "/Home"} element={<Home />} />
          <Route path={process.env.PUBLIC_URL + "/FAQ"} element={<FAQ />} />

          <Route
            path={process.env.PUBLIC_URL + "/om-oss"}
            element={<AboutUs />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/losningar"}
            element={<PricingTable />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/kontakt"}
            element={<Contact />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/404"}
            element={<ErrorPage />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/integritetspolicy"}
            element={<PrivacyPolicy />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/terms-use"}
            element={<TermsOfUse />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/hur-fungerar-det"}
            element={<HowItWorks />}
          />
        </Routes>
      </ScrollToTop>
    </Router>
  );
};

export default App;
