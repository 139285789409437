import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import SEO from "../common/SEO";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import AboutFour from "../component/about/AboutFour";
import FormOne from "../component/contact/FormOne";

const AboutUs = () => {
  return (
    <>
      <SEO title="Om oss" />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <div className="case-study-solution pt--250">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 offset-lg-2">
                        <div className="section-heading heading-left">
                            <h2 className="title">Om oss</h2>
                            <p>LisaHem grundades 2021 med ambitionen att göra det möjligt för villa- och radhusägare att frigöra kapital från sin bostad utan att behöva flytta.</p> 
                            <p>LisaHem är en del av det privatägda fastighetsbolaget Ivato AB som äger och förvaltar bostadsfastigheter till ett värde av 300 miljoner kronor. Bolaget är finansierat av ägarna och med sedvanlig bankfinansiering.</p>
                        </div>
                        <div className="about-expert">
                            <div className="thumbnail">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ul className="shape-group-10 list-unstyled">
                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"} alt="Line" /></li>
                <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"} alt="Line" /></li>
            </ul>
        </div>
        <AboutFour />
        <div className="section">
          <div className="container">
            <div className="row">
              <div className="col-xl-8 container">
                <h2 className="title text-center">Ansök och få ett bud</h2>
                <p className="text-center">
                  Skicka dina uppgifter så återkommer vi med ett ungefärligt bud
                  och hyresnivå. Det är givetvis kostnadsfritt och icke
                  bindande.
                </p>
                <FormOne />
              </div>
            </div>
          </div>
        </div>
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default AboutUs;
