import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import BreadCrumbOne from "../elements/breadcrumb/BreadCrumbOne";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import SEO from "../common/SEO";

const PrivacyPolicy = () => {
  return (
    <>
      <SEO title="Privacy Policy" />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <BreadCrumbOne title="Integritetspolicy" page="Integritetspolicy" />
        <div className="section-padding privacy-policy-area">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="privacy-policy-content">
                  <div className="section-title">
                    LisaHem värnar om din personliga integritet. LisaHem arbetar
                    därför aktivt för att din integritet ska vara skyddad när du
                    använder dig av våra tjänster. Denna integritetspolicy
                    förklarar hur vi samlar in och använder dina
                    personuppgifter. Den beskriver också dina rättigheter och
                    hur du kan göra dem gällande. LisaHem är
                    personuppgiftsansvariga för behandlingen av dina
                    personuppgifter.
                  </div>
                  <h4>Vad är en personuppgift?</h4>
                  <p>
                    Personuppgifter är all information som rör en identifierad
                    eller identifierbar levande fysisk person; en identifierbar
                    fysisk person är en person som kan identifieras, direkt
                    eller indirekt, exempelvis genom namn, personnummer, adress
                    men även krypterade uppgifter och olika slags elektroniska
                    identiteter (exempelvis IP-nummer) utgör personuppgifter
                    under förutsättning att de kan kopplas samman till en fysisk
                    person. Med behandling av personuppgifter avses allting som
                    sker med personuppgifterna. Det omfattar bland annat hur de
                    samlas in, lagras, ändras, raderas och utlämnas.
                  </p>
                  <h4>Vad använder vi dina personuppgifter till?</h4>
                  <p>
                    Genom att lämna dina personuppgifter samtycker du till att
                    vi använder insamlad information för att fullgöra våra
                    åtaganden mot dig och tillhandahålla den service du
                    förväntar dig. Vi behöver dina personuppgifter för bland
                    annat följande ändamål: För att ta registrera dig som kund
                    hos oss och kunna erbjuda dig våra tjänster (vi samlar
                    enbart in namn, adress, mobiltelefonnummer och mailadress)
                    För att via SMS kunna avisera påminnelser (skickas till ditt
                    mobiltelefonnummer) För att kunna skicka marknadsföring
                    t.ex. nyhetsbrev, SMS (skickas till din e-postadress, till
                    ditt mobiltelefonnummer och/eller till din postadress) För
                    att kunna svara på dina frågor och för att informera dig om
                    nya eller ändrade tjänster (kommunikation med dig sker via
                    din e-postadress eller din postadress) Hur länge sparar vi
                    dina personuppgifter? Vi sparar inte dina personuppgifter
                    längre än vad som är nödvändigt för respektive ändamål. I de
                    fall särskilda lagregler föreskriver viss lagringstid följer
                    vi denna specifika tid för sparande av dina personuppgifter.
                  </p>
                  <h4>Hur länge sparar vi dina personuppgifter?</h4>
                  <p>
                    Vi sparar inte dina personuppgifter längre än vad som är
                    nödvändigt för respektive ändamål. I de fall särskilda
                    lagregler föreskriver viss lagringstid följer vi denna
                    specifika tid för sparande av dina personuppgifter.
                  </p>
                  <h4>Vad har du för rättigheter?</h4>
                  <p>
                    Du har rätt att begära ut information om vilka
                    personuppgifter vi behandlar, eller har tillgång till, om
                    dig (registerutdrag). För att ingen annan ska kunna ges
                    tillgång till dina uppgifter måste du identifiera dig när du
                    begär registerutdrag. LisaHem är skyldiga att ha korrekta
                    och aktuella personuppgifter om dig. Om dina personuppgifter
                    är felaktiga kan du begära rättelse eller komplettering av
                    dessa. Du har även rätt att begära att vi begränsar
                    omfattningen av personuppgifter som behandlas om dig vid
                    exempelvis utredning av vårt berättigade intresse. Under
                    vissa förutsättningar kan du begära att uppgifter om dig
                    raderas när de inte längre är nödvändiga för det ändamål för
                    vilka de samlades in. LisaHem tar bort uppgifter om dig när
                    dessa inte längre behövs, men kan på grund av annan
                    lagstiftning vara skyldiga att spara data om dig för att
                    uppfylla lagkrav. Du har rätt att invända mot sådan
                    behandling av uppgifter som grundar sig på berättigat
                    intresse. Du har även rätt att invända mot
                    direktmarknadsföring. Du kan begära att få ta del av de
                    personuppgifter som du själv har tillhandahållit och som
                    LisaHem behandlar elektroniskt, genom en elektronisk kopia.
                    Du kan även begära att den elektroniska kopian ska överföras
                    direkt till en annan personuppgiftsansvarig om detta är
                    tekniskt möjligt. För att göra någon av dina rättigheter
                    gällande kontakta info@lisahem.se. Du har också alltid rätt
                    att lämna in ett klagomål eller lämna in anmälan om
                    överträdelser av GDPR till ansvarig tillsynsmyndighet,
                    Datainspektionen.
                  </p>
                  <h4>Vem får tillgång till dina personuppgifter?</h4>
                  <p>
                    Dina personuppgifter behandlas av LisaHems anställda som
                    behöver ta del av dina personuppgifter för att kunna utföra
                    sitt arbete. Vi lämnar inte ut personuppgifter till andra om
                    vi inte behöver göra detta på grund av lag, förordning eller
                    myndighetsbeslut eller för att skydda våra rättigheter eller
                    tredje parts rättigheter. LisaHem kan komma att dela dina
                    personuppgifter med organisationer som kreditupplysnings-
                    eller inkassoföretag för tillämpning av kreditprövning,
                    identitetskontroll, övervakning av kreditvärdighet och
                    övertagande av fordran.
                  </p>
                  <h4>Hur skyddar vi dina uppgifter?</h4>
                  <p>
                    Vi har vidtagit tekniska och organisatoriska åtgärder för
                    att skydda dina uppgifter mot t.ex. förlust, manipulation
                    eller obehörig åtkomst. Vi anpassar kontinuerligt våra
                    säkerhetsåtgärder till den fortlöpande tekniska
                    utvecklingen. I kommunikation med dig som kund via webben
                    skyddar vi dina uppgifter med hjälp av kryptering via SSL.
                    Endast de personer som faktiskt behöver behandla dina
                    personuppgifter för att vi ska kunna uppfylla våra angivna
                    ändamål har tillgång till dem.
                  </p>
                  <h4>Kontakta oss</h4>
                  <p>
                    Om du har någon fråga eller fundering kring hur vi hanterar
                    dina personuppgifter, eller vill göra någon av dina
                    rättigheter gällande kan du kontakta oss på info@lisahem.se.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default PrivacyPolicy;
