import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import BcrumbBannerTwo from "../elements/breadcrumb/BcrumbBannerTwo";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import SEO from "../common/SEO";
import FaqTwo from "../component/faq/FaqTwo";
import FormOne from "../component/contact/FormOne";


const PayDebt = () => {

  return (
    <>
      <SEO title="FAQ" />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <BcrumbBannerTwo
          title={"Hur fungerar LisaHem?"}
          paragraph={
            "Här hittar du svar på alla frågor du kan tänkas ha om hur LisaHem fungerar."
          }
          mainThumb={"/images/new/FAQ.svg"}
        />

        <FaqTwo />

        <div className="section section-padding">
          <div className="container">
            <div className="row">
              <div className="col-xl-8 container">
                <h2 className="title text-center">Ansök och få ett bud</h2>
                <p className="text-center">
                  Skicka dina uppgifter så återkommer vi med ett ungefärligt bud
                  och hyresnivå. Det är givetvis kostnadsfritt och icke
                  bindande.
                </p>
                <FormOne />
              </div>
            </div>
          </div>
        </div>

        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default PayDebt;
