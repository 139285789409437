import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import ProcessOne from "../component/process/ProcessOne";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import SEO from "../common/SEO";
import Testimonials from "./Testimonials";
import FormOne from "../component/contact/FormOne";


const HowItWorks = () => {
  return (
    <>
      <SEO title="Sälj och bo kvar" />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <div className="section section-padding-3 bg-color-light">
          <ProcessOne/>
        </div>
        <Testimonials />
        <div className="section section-padding">
          <div className="container">
            <div className="row">
              <div className="col-xl-8 container">
                <h2 className="title text-left">Ansök och få ett bud</h2>
                <p className="text-left">
                  Skicka dina uppgifter så återkommer vi med ett ungefärligt bud
                  och hyresnivå. Det är givetvis kostnadsfritt och icke
                  bindande.
                </p>
                <FormOne />
              </div>
            </div>
          </div>
        </div>
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default HowItWorks;
