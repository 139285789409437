import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SectionTitle from '../elements/section-title/SectionTitle';
import PricingOne from '../component/pricing/PricingOne';
import FaqOne from '../component/faq/FaqOne';
import FormOne from '../component/contact/FormOne';


const PricingTable = () => {

    return (
        <>
        <SEO title="Lösningar" />
        <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <div className="section section-padding-3 pt--250 bg-color-light">
                    <div className="container">
                        <SectionTitle 
                            subtitle="Superflexibla lösningar"
                            title="Våra erbjudanden"
                            description="LisaHem kan hjälpa dig att sälja huset sedan får du själv bestämma hur länge du vill bo kvar. Vi kan även erbjuda dig att köpa tillbaka huset efter hyresperioden."
                            textAlignment="mb--120"
                            textColor=""
                        />
                        <PricingOne />
                    </div>
                    <ul className="shape-group-3 list-unstyled">
                        <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-4.png"} alt="shape" /></li>
                    </ul>
                </div>
                <FaqOne className="bg-color-white" />
                <div className="section section-padding">
                    <div className="container">
                        <div className="row">
                          <div className="col-xl-8 container">
                            <h2 className="title text-left">Ansök och få ett bud</h2>
                               <p className="text-left">
                                    Skicka dina uppgifter så återkommer vi med ett ungefärligt bud
                                    och hyresnivå. Det är givetvis kostnadsfritt och icke
                                    bindande.
                                </p>
                                <FormOne />
                            </div>
                        </div>
                    </div>
                </div>
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default PricingTable;