import React from "react";
import FormOne from "../contact/FormOne";

const AboutOne = () => {
  return (
    <section className="section section-padding-3 bg-color-light">
      <p style={{ padding: 20 }}></p>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="about-us">
              <div className="section-heading heading-left mb-0">
                <h2 className="title mb--40">
                  Sälj och bo kvar <br /> – leasa ditt hem.
                </h2>
                <p>
                LisaHem köper din villa eller radhus medan du bor kvar som hyresgäst. Du frigör kapital bundet i bostaden utan att behöva flytta. {" "}
                </p>
                <p>
                Lämna dina uppgifter så kontaktar vi dig!{" "}
                </p>
              </div>
            </div>
          </div>

          <div className="col-xl-5 col-lg-6 offset-xl-1">
            <div className="contact-form-box">
              <h3 className="title">Ansök och få ett bud</h3>
              <FormOne />
            </div>
          </div>
        </div>
      </div>
      <ul className="shape-group-6 list-unstyled">
        <li className="shape shape-1">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-7.png"}
            alt="Bubble"
          />
        </li>

        <li className="shape shape-3">
          <img
            src={process.env.PUBLIC_URL + "/images/others/line-5.png"}
            alt="line"
          />
        </li>
      </ul>
    </section>
  );
};

export default AboutOne;
